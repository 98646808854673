
import { defineComponent } from 'vue'
import {
  IonButton,
  IonIcon,
  pickerController,
  toastController,
} from '@ionic/vue'
import { checkmarkOutline } from 'ionicons/icons'
import axios from '@/zenky'

export default defineComponent({
  props: {
    order: {
      required: true,
      type: Object,
    },

    variant: {
      required: true,
      type: Object,
    },
  },

  components: {
    IonButton,
    IonIcon,
  },

  computed: {
    isFullyAssembled(): boolean {
      return this.variant.quantity === this.currentAssembledQuantity
    },

    quantityItems(): any {
      const values = []

      for (let i = 0; i <= this.variant.quantity; ++i) {
        values.push({
          value: i,
          text: i,
        })
      }

      return {
        name: 'assembled_quantity',
        options: values.reverse(),
      }
    },

    currentAssembledQuantity(): number|null {
      if (this.assembledQuantity !== null) {
        return this.assembledQuantity
      } else if (this.variant.assembled_quantity !== null) {
        return this.variant.assembled_quantity
      }

      return null
    },
  },

  methods: {
    async openQuantityPicker() {
      const picker = await pickerController.create({
        columns: [this.quantityItems],
        buttons: [
          {
            text: 'Отмена',
            role: 'cancel',
          },
          {
            text: 'Сохранить',
            handler: (value: any) => {
              this.assembledQuantity = value.assembled_quantity.value
            },
          },
        ]
      })

      await picker.present()
    },

    setFullyAssembled() {
      if (this.assembledQuantity === this.variant.quantity) {
        return this.showSuccessfulNotification()
      }

      this.assembledQuantity = this.variant.quantity
    },

    async showSuccessfulNotification() {
      const toast = await toastController.create({
        message: 'Количество изменено.',
        duration: 2000,
        color: 'primary',
        cssClass: 'text-white',
      })

      await toast.present()
    },

    async showErrorNotification() {
      const toast = await toastController.create({
        message: 'Не удалось изменить количество. Попробуйте ещё раз.',
        duration: 2000,
        color: 'danger',
        cssClass: 'text-white',
      })

      await toast.present()
    },

    async updateAssembledQuantity() {
      if (this.assembledQuantity === null) {
        return
      }

      const form = { quantity: this.assembledQuantity }

      try {
        await axios.post(`orders/${this.order.id}/products/${this.variant.uuid}/assemble`, form)
        await this.showSuccessfulNotification()
        this.$emit('assembled', this.assembledQuantity)
      } catch (e) {
        await this.showErrorNotification()
      }
    },
  },

  watch: {
    assembledQuantity() {
      this.updateAssembledQuantity()
    },
  },

  data() {
    return {
      assembledQuantity: null,
      icons: {
        checkmarkOutline,
      },
    }
  }
})
