
import { defineComponent } from 'vue'
import { alertController, IonItem, IonLabel, IonSelect, IonSelectOption, toastController, loadingController } from '@ionic/vue'
import { mapActions, mapGetters } from 'vuex'
import OrderStatus from '@/zenky/models/orders/statuses/OrderStatus'
import HasVisibleStatuses from '@/mixins/HasVisibleStatuses'
import OrderStatusTransition from '@/zenky/models/orders/statuses/OrderStatusTransition'
import Enum from '@/zenky/models/common/Enum'
import axios from '@/zenky'
import errorsHandler from '@/zenky/errors'

export default defineComponent({
  mixins: [HasVisibleStatuses],

  props: {
    order: {
      required: true,
      type: Object,
    },
  },

  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },

  created() {
    this.orderStatusId = this.order.order_status_id
    this.currentOrderStatusId = this.order.order_status_id
    this.ready = true
  },

  computed: {
    ...mapGetters({
      employee: 'auth/employee',
      statuses: 'orders/statuses',
      statusUpdates: 'orders/statusUpdates',
      transitions: 'orders/transitions',
    }),

    orderStatusIsUpdating(): boolean {
      if (typeof this.statusUpdates[this.order.id] === 'undefined') {
        return false
      }

      return this.statusUpdates[this.order.id] === true
    },

    visibleStatuses(): OrderStatus[] {
      return this.getVisibleStatuses(this.statuses, this.employee)
    },

    allowedStatuses(): OrderStatus[] {
      if (!this.hasTransitions(this.currentOrderStatusId)) {
        return this.statuses
      }

      const allowedTransitions = this.getAllowedTransitionsFor(this.currentOrderStatusId)

      return this.statuses.filter((status: OrderStatus) => {
        if (status.id === this.currentOrderStatusId) {
          return true
        }

        return allowedTransitions.some((transition: OrderStatusTransition) => transition.transition_order_status_id === status.id)
      })
    },
  },

  methods: {
    ...mapActions({
      updateOrderStatus: 'orders/updateOrderStatus',
    }),

    async updateStatus() {
      if (this.currentOrderStatusId === this.orderStatusId) {
        console.log('[OrderStatusUpdater@updateStatus] Order has the same order status ID, ignoring.')

        return
      } else if (this.orderStatusIsUpdating) {
        console.log('[OrderStatusUpdater@updateStatus] Status is already updating')

        return
      }

      const status = this.statuses.find((status: any) => status.id === this.orderStatusId)
      const transition = this.getTransition(this.currentOrderStatusId, status.id)

      console.log('[OrderStatusUpdater@updateStatus] Updating order status.', {
        status,
        transition,
        current_status: this.currentOrderStatusId,
      })

      if (this.hasTransitions(this.currentOrderStatusId) && transition === null) {
        console.log('[OrderStatusUpdater@updateStatus] Order has transitions but there is no transition for selected status.', {
          status,
        })

        return this.displayTransitionMessage(status)
      }

      if (transition !== null && transition.confirmation_required) {
        console.log('[OrderStatusUpdater@updateStatus] Transition requires confirmation.', {
          status,
          transition,
        })

        return this.updateStatusWithConfirmation(transition)
      }

      console.log('[OrderStatusUpdater@updateStatus] Updating order status.', {
        status,
        transition,
      })

      await this.performStatusUpdate({
        method: 'without_confirmation',
        current_status: this.currentOrderStatusId,
        transition_status: status.id,
        transition_id: transition ? transition.id : null,
        transition_confirmation: transition ? { required: transition.confirmation_required, confirmation: transition.confirmation } : null,
      })
    },

    shouldAssembleBeforeStatusUpdate(): boolean {
      const currentStatus = this.statuses.find((status: OrderStatus) => status.id === this.currentOrderStatusId)

      if (!currentStatus) {
        return false
      }

      return currentStatus.kind.id === 'packing'
    },

    async assembleOrder(): Promise<boolean> {
      const loading = await loadingController.create({
        message: 'Завершаем сборку...',
        spinner: 'crescent',
      })

      await loading.present()

      try {
        await axios.post(`orders/${this.order.id}/products/assemble`)
        await loading.dismiss()

        const toast = await toastController.create({
          message: 'Сборка завершена.',
          duration: 2000,
          color: 'primary',
          cssClass: 'text-white',
        })

        await toast.present()

        return Promise.resolve(true)
      } catch (e) {
        await loading.dismiss()

        const message = errorsHandler.getMessage(e.response, 'Не удалось завершить сборку заказа.')

        const toast = await toastController.create({
          message,
          duration: 2000,
          color: 'danger',
          cssClass: 'text-white',
        })

        await toast.present()

        this.orderStatusId = this.previousOrderStatusId
      }

      return Promise.resolve(false)
    },

    async performStatusUpdate(meta: any) {
      let assembled = true

      if (this.shouldAssembleBeforeStatusUpdate()) {
        assembled = await this.assembleOrder()
      }

      if (!assembled) {
        return
      }

      const loading = await loadingController.create({
        message: 'Изменяем статус...',
        spinner: 'crescent',
      })

      await loading.present()

      try {
        await this.updateOrderStatus({
          orderId: this.order.id,
          statusId: this.orderStatusId,
          meta: meta,
        })

        await loading.dismiss()

        const toast = await toastController.create({
          message: 'Статус изменён.',
          duration: 2000,
          color: 'primary',
          cssClass: 'text-white',
        })

        await toast.present()

        this.currentOrderStatusId = this.orderStatusId
      } catch (e) {
        await loading.dismiss()

        const message = errorsHandler.getMessage(e.response, 'Не удалось изменить статус заказа.')

        const toast = await toastController.create({
          message,
          duration: 2000,
          color: 'danger',
          cssClass: 'text-white',
        })

        await toast.present()

        this.orderStatusId = this.previousOrderStatusId
      }
    },

    async displayTransitionMessage(status: OrderStatus) {
      const alert = await alertController.create({
        message: `Этот заказ нельзя перевести в статус "${status.name}"!`,
        header: 'Ошибка изменения статуса',
      })

      return alert.present
    },

    async updateStatusWithConfirmation(transition: OrderStatusTransition) {
      const message = transition.confirmation || 'Вы уверены, что хотите изменить статус заказа?'

      const alert = await alertController.create({
        message,
        header: 'Изменение статуса',
        buttons: [
          {
            text: 'Не изменять',
            role: 'cancel',
            handler: () => {
              this.orderStatusId = this.previousOrderStatusId
            },
          },
          {
            text: 'Изменить',
            role: 'confirm',
            handler: async () => {
              await this.performStatusUpdate({
                method: 'confirmation',
                current_status: this.currentOrderStatusId,
                transition_status: transition.transition_order_status_id,
                transition_id: transition.id,
                transition_confirmation: {
                  required: transition.confirmation_required,
                  confirmation: transition.confirmation
                },
              })
            },
          },
        ],
      })

      return alert.present()
    },

    getTransitionsFor(statusId: string): OrderStatusTransition[] {
      return this.transitions.filter((transition: OrderStatusTransition) => transition.order_status_id === statusId)
    },

    hasTransitions(statusId: string): boolean {
      return this.getTransitionsFor(statusId).length > 0
    },

    getAllowedTransitionsFor(statusId: string): OrderStatusTransition[] {
      return this.getTransitionsFor(statusId).filter((transition: OrderStatusTransition) => {
        if (transition.roles === null) {
          return true
        }

        return transition.roles.some((role: Enum) => role.id === this.employee.role.id)
      })
    },

    getTransition(statusId: string, transitionStatusId: string): OrderStatusTransition|null {
      const transition = this.getTransitionsFor(statusId).find((transition: OrderStatusTransition) => {
        return transition.transition_order_status_id === transitionStatusId
      })

      if (!transition) {
        return null
      }

      return transition
    },
  },

  watch: {
    ['order.order_status_id']() {
      this.orderStatusId = this.order.order_status_id
      this.currentOrderStatusId = this.order.order_status_id
    },

    orderStatusId(val, oldVal) {
      this.previousOrderStatusId = oldVal
    },
  },

  data() {
    return {
      ready: false,
      orderStatusId: '',
      currentOrderStatusId: '',
      previousOrderStatusId: '',
      statusSelectStyles: {
        '--padding-start': 0,
        '--padding-end': 0,
      },
    }
  }
})
