
import { defineComponent, inject, computed } from 'vue'
import { useStore } from 'vuex'
import {
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonCardHeader,
  IonButton,
  modalController,
} from '@ionic/vue'
import DetailOrderCard from '@/components/orders/DetailOrderCard.vue'
import OrderSummary from '@/components/orders/OrderSummary.vue'
import OrderStatusUpdater from '@/components/orders/OrderStatusUpdater.vue'

export default defineComponent({
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonButton,
    OrderSummary,
    OrderStatusUpdater,
  },

  props: {
    orderId: {
      required: true,
      type: String,
    }
  },

  setup(props) {
    const outlet: any = inject('routerOutlet')
    const store = useStore()

    const openDetailModal = async () => {
      const presentingElement = (await modalController.getTop()) || outlet.value.$el

      const modal = await modalController.create({
        presentingElement,
        component: DetailOrderCard,
        componentProps: {
          orderId: props.orderId,
        },
        swipeToClose: true,
      })

      return modal.present()
    }

    return {
      openDetailModal,
      order: computed(() => store.getters['orders/orders'].find((order: any) => order.id === props.orderId)),
    }
  },
})
