
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  toastController,
} from '@ionic/vue'
import OrderCard from '@/components/orders/OrderCard.vue'
import storage from '@/zenky/storage'
import OrderStatus from '@/zenky/models/orders/statuses/OrderStatus'
import HasVisibleStatuses from '@/mixins/HasVisibleStatuses'

export default defineComponent({
  mixins: [HasVisibleStatuses],

  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSearchbar,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonButton,
    OrderCard,
  },

  async created() {
    this.loading = true

    await this.loadStatuses()
    await this.loadTransitions()

    this.params.order_status_id = await this.getAvailableStatusesFromStorage()
    this.params.stock_id = await this.getStocksFromStorage()

    await this.reloadOrders()

    this.loading = false
  },

  computed: {
    ...mapGetters({
      orders: 'orders/orders',
      statuses: 'orders/statuses',
      ordersAreLoading: 'orders/loading',
      pagination: 'orders/pagination',
      employee: 'auth/employee',
      cities: 'auth/cities',
    }),

    nextPage(): number {
      if (this.pagination === null) {
        return 1
      }

      return this.pagination.current_page + 1
    },

    requestParams() {
      const params: {[index: string]: any} = {
        count: 10,
        page: this.nextPage,
        search: this.params.search,
        order_status_id: this.params.order_status_id.join(','),
      }

      if (this.params.stock_id.length > 0) {
        params.stock_id = this.params.stock_id.join(',')
      }

      return params
    },

    visibleStatuses(): OrderStatus[] {
      return this.getVisibleStatuses(this.statuses, this.employee)
    },

    visibleStatusIds(): string[] {
      return this.visibleStatuses.map((status: OrderStatus) => status.id)
    },

    stockFilterOptions(): any[] {
      let options: any[] = []

      this.cities.forEach((city: any) => {
        if (this.cities.length > 1) {
          options.push({
            id: `city-${city.id}`,
            type: 'city',
            label: city.name,
            value: null,
            disabled: true,
            city_id: city.id,
          })
        }

        city.stocks.forEach((stock: any) => {
          options.push({
            id: `city-${city.id}-stock-${stock.id}`,
            type: 'stock',
            label: stock.name || stock.display_name,
            value: stock.id,
            disabled: false,
            city_id: city.id,
            stock_id: stock.id,
          })
        })
      })

      if (this.employee.city_id !== null) {
        options = options.filter((option: any) => option.city_id === this.employee.city_id)
      }

      if (this.employee.stocks.length > 0) {
        const stocks = this.employee.stocks.map((stock: any) => stock.id)

        options = options.filter((option: any) => option.type === 'city' || stocks.indexOf(option.stock_id) !== -1)
      }

      options = options.filter((option: any) => {
        if (option.type === 'stock') {
          return true
        }

        return options.filter((item: any) => item.type === 'stock' && item.city_id === option.city_id).length > 0
      })

      return options
    },

    availableStocks(): string[] {
      return this.stockFilterOptions
        .filter((option: any) => option.type === 'stock')
        .map((option: any) => option.stock_id)
    },
  },

  methods: {
    ...mapActions({
      loadOrders: 'orders/loadOrders',
      loadStatuses: 'orders/loadStatuses',
      loadTransitions: 'orders/loadTransitions',
    }),

    ...mapMutations({
      setPagination: 'orders/setPagination',
    }),

    resetFilters() {
      this.params.search = ''
      this.params.order_status_id = this.visibleStatuses.map((status: OrderStatus) => status.id)
      this.params.stock_id = this.availableStocks
      this.reloadOrders()
    },

    async reloadOrders() {
      if (this.ordersAreLoading) {
        return
      }

      this.setPagination(null)

      await this.load(true)
    },

    async load(replace = false) {
      if (this.ordersAreLoading) {
        return
      } else if (!this.requestParams.order_status_id) {
        return this.displayOrderStatusError()
      }

      try {
        await this.loadOrders({
          replace,
          params: this.requestParams,
        })
      } catch (e) {
        const toast = await toastController.create({
          message: 'Не удалось загрузить заказы. Перезапустите приложение.',
          duration: 2000,
          color: 'danger',
          cssClass: 'text-white',
        })

        await toast.present()
      }
    },

    async displayOrderStatusError() {
      const toast = await toastController.create({
        message: 'Нужно выбрать хотя бы один статус заказа!',
        duration: 2000,
        color: 'danger',
        cssClass: 'text-white',
      })

      await toast.present()
    },

    async getAvailableStatusesFromStorage() {
      const statuses = await storage.getOrderStatusFilter()

      if (!statuses.length) {
        return this.visibleStatuses.map((status: OrderStatus) => status.id)
      }

      return statuses.filter((statusId: string) => this.visibleStatusIds.indexOf(statusId) !== -1)
    },

    async getStocksFromStorage() {
      const stocks = await storage.getOrderStocksFilter()

      if (!stocks.length) {
        return this.availableStocks
      } else if (!this.availableStocks.length) {
        return []
      }

      return stocks.filter((stockId: string) => this.availableStocks.indexOf(stockId) !== -1)
    },

    onSearchQueryChange() {
      this.reloadOrders()
    },
  },

  watch: {
    async ['params.order_status_id']() {
      await storage.setOrderStatusFilter(this.params.order_status_id)

      setTimeout(() => this.reloadOrders(), 100)
    },

    async ['params.stock_id']() {
      await storage.setOrderStocksFilter(this.params.stock_id)

      setTimeout(() => this.reloadOrders(), 100)
    },
  },

  data() {
    return {
      loading: false,
      params: {
        search: '',
        order_status_id: [] as string[],
        stock_id: [] as string[],
      },
    }
  }
})
