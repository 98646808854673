
import { defineComponent } from 'vue'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  modalController,
} from '@ionic/vue'
import OrderSummary from '@/components/orders/OrderSummary.vue'
import OrderProductVariantCard from '@/components/orders/OrderProductVariantCard.vue'
import OrderStatusUpdater from '@/components/orders/OrderStatusUpdater.vue'
import HasOrders from '@/mixins/HasOrders'
import axios from '@/zenky'

export default defineComponent({
  mixins: [HasOrders],

  props: {
    orderId: {
      required: true,
      type: String,
    },
  },

  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    OrderSummary,
    OrderProductVariantCard,
    OrderStatusUpdater,
  },

  mounted() {
    if (this.isPacking) {
      this.reloadAssembledPrice()
    }
  },

  computed: {
    order(): any {
      return this.getOrder()
    },

    simpleOrder(): any {
      return {
        id: this.order.id,
        status: this.order.status,
      }
    },

    isPacking(): boolean {
      if (!this.order.status || !this.order.status.id) {
        return false
      }

      return this.order.status.id === 'packing'
    },

    shouldDisplayAssembledPrice(): boolean {
      if (this.assembledPrice.price === null) {
        return false
      }

      return this.assembledPrice.price.value !== this.order.total_price.value
    },
  },

  methods: {
    dismissModal() {
      modalController.dismiss()
    },

    async reloadAssembledPrice() {
      if (this.assembledPrice.loading) {
        return
      }

      this.assembledPrice.loading = true
      this.assembledPrice.price = (await axios.get(`orders/${this.order.id}/products/assembly/price`)).data.data
      this.assembledPrice.loading = false
    },
  },

  data() {
    return {
      assembledPrice: {
        loading: false,
        price: null as any|null,
      },
    }
  }
})
