
import { defineComponent } from 'vue'
import { pluralize } from '@/zenky/helpers'
import { createOutline, openOutline, clipboardOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/vue'
import ClipboardButton from '@/components/clipboard/ClipboardButton.vue'

export default defineComponent({
  props: {
    order: {
      required: true,
      type: Object,
    },
  },

  components: {
    IonIcon,
    ClipboardButton,
  },

  computed: {
    quantity(): number {
      return this.order.variants.reduce((total: number, current: any) => current.quantity + total, 0)
    },

    variantsCountText(): string {
      return pluralize(this.quantity, ['товар', 'товара', 'товаров'])
    },

    deliveryAddress(): string {
      if (!this.order.delivery_address) {
        return ''
      }

      let address = `${this.order.delivery_address.full}\n`

      if (this.order.delivery_address.apartment) {
        address += `Квартира/офис: ${this.order.delivery_address.apartment}\n`
      }

      if (this.order.delivery_address.entrance) {
        address += `Подъезд/вход: ${this.order.delivery_address.entrance}\n`
      }

      if (this.order.delivery_address.floor) {
        address += `Этаж: ${this.order.delivery_address.floor}\n`
      }

      if (this.order.delivery_address.has_intercom) {
        address += `Домофон работает\n`
      } else {
        address += `Домофон не работает/отсутствует`
      }

      return address.trim()
    },

    copyText(): string {
      let text = `Заказ №${this.order.number}\n`
      text += `Сумма: ${this.order.total_price.full}\n`

      if (this.order.preview_url) {
        text += `Ссылка: ${this.order.preview_url}\n\n`
      }

      if (this.order.delivery_address) {
        text += `Адрес: ${this.deliveryAddress}\n`
      }

      return text.trim()
    },
  },

  data() {
    return {
      icons: {
        createOutline,
        openOutline,
        clipboardOutline,
      },
    }
  }
})
