export default {
  methods: {
    getProductVariantDescription(variant: any): string {
      if (this.hasProductVariantOptionValuesList(variant)) {
        return this.getProductvariantOptionsDescription(variant)
      } else if (variant.variant.name) {
        return variant.variant.name
      } else if (variant.variant.sku) {
        return variant.variant.sku
      }

      return ''
    },

    hasProductVariantOptionValuesList(variant: any): boolean {
      if (!variant.variant || !variant.variant.option_values) {
        return false
      }

      return variant.variant.option_values.length && variant.variant.option_values.length > 0
    },

    getProductvariantOptionsDescription(variant: any): string {
      if (!variant.variant || !variant.variant.option_values) {
        return ''
      } else if (!variant.variant.option_values.length) {
        return ''
      }

      return variant.variant.option_values.map((ov: any) => {
        if (!ov.option || !ov.option.name) {
          return ov.name
        }

        return `${ov.option.name}: ${ov.name}`
      }).join(' / ')
    },

    hasProductVariantModifiers(variant: any): boolean {
      return Array.isArray(variant.modifiers) && variant.modifiers.length > 0
    },

    getProductVariantModifiers(variant: any): any[] {
      if (!this.hasProductVariantModifiers(variant)) {
        return []
      }

      return variant.modifiers.map((modifier: any) => {
        if (modifier.modifiers_group_id === null) {
          return {
            modifier_id: modifier.modifier_id,
            quantity: modifier.quantity,
          }
        }

        return {
          modifier_id: modifier.modifier_id,
          modifiers_group_id: modifier.modifiers_group_id,
          quantity: modifier.quantity,
        }
      })
    },

    getProductVariantModifiersList(variant: any): any[] {
      return variant.modifiers.map((modifier: any) => {
        if (modifier.modifiers_group === null) {
          return {
            hasGroup: false,
            modifier: modifier.modifier.display_name || modifier.modifier.name,
            quantity: modifier.quantity,
            total_price: modifier.total_price,
          }
        }

        return {
          hasGroup: true,
          group: modifier.modifiers_group.display_name || modifier.modifiers_group.name,
          modifier: modifier.modifier.display_name || modifier.modifier.name,
          quantity: modifier.quantity,
          total_price: modifier.total_price,
        }
      })
    },
  },
}
