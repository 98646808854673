
import { defineComponent } from 'vue'
import HasProductVariantDescription from '@/mixins/HasProductVariantDescription'
import OrderProductVariantAssembly from '@/components/orders/OrderProductVariantAssembly.vue'

export default defineComponent({
  mixins: [HasProductVariantDescription],

  components: {
    OrderProductVariantAssembly,
  },

  props: {
    variant: {
      required: true,
      type: Object,
    },

    order: {
      required: true,
      type: Object,
    },
  },

  computed: {
    variantName(): string {
      if (this.variant.variant.name) {
        return this.variant.variant.name
      }

      return this.variant.product.name
    },

    variantDescription(): string {
      return this.getProductVariantDescription(this.variant)
    },

    hasModifiers(): boolean {
      return this.hasProductVariantModifiers(this.variant)
    },

    variantModifiersList(): any[] {
      return this.getProductVariantModifiersList(this.variant)
    },

    product(): any {
      return this.variant.product
    },

    hasImages(): boolean {
      return !(!this.product || !this.product.media || !Array.isArray(this.product.media) || !this.product.media.length)
    },

    firstMediaUrl(): string|null {
      if (!this.hasImages) {
        return null
      }

      return this.product.media[0].images.medium.url
    },

    isAssembling(): boolean {
      return this.order.status.id === 'packing'
    },

    currentAssembledQuantity(): number|null {
      if (this.assembledQuantity !== null) {
        return this.assembledQuantity
      }

      return this.variant.assembled_quantity
    },

    background() {
      if (this.currentAssembledQuantity === null) {
        return []
      } else if (this.currentAssembledQuantity < this.variant.quantity) {
        return ['bg-red-100']
      }

      return []
    },
  },

  methods: {
    variantAssembled(assembledQuantity: number) {
      this.$emit('assembled', assembledQuantity)
      this.assembledQuantity = assembledQuantity
    },
  },

  data() {
    return {
      assembledQuantity: null as number|null,
    }
  }
})
