import { mapGetters } from 'vuex'

export default {
  props: {
    orderId: {
      required: true,
      type: String,
    },
  },

  computed: {
    ...mapGetters({
      orders: 'orders/orders',
    }),
  },

  methods: {
    getOrder(): any {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore

      if (!this.orders.length) {
        return null
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return this.orders.find((order: any) => order.id === this.orderId)
    }
  }
}
