import OrderStatus from '@/zenky/models/orders/statuses/OrderStatus'
import Employee from '@/zenky/models/store/Employee'
import StoreRole from '@/zenky/enums/StoreRole'
import Enum from '@/zenky/models/common/Enum'

export default {
  methods: {
    getVisibleStatuses(statuses: OrderStatus[], employee: Employee): OrderStatus[] {
      if (!employee || !employee.role) {
        return []
      } else if (!statuses.length) {
        return []
      } else if ([StoreRole.OWNER, StoreRole.MANAGER].indexOf(employee.role.id) !== -1) {
        return statuses
      }

      return statuses.filter((status: OrderStatus) => {
        if (status.roles === null) {
          return true
        }

        return status.roles.findIndex((role: Enum) => role.id === employee.role.id) !== -1
      })
    },
  },
}
